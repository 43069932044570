<template>
  <router-view/>
</template>

<style lang="scss">
#app {
  width: 100%;
  height: 100%;
}
</style>
